export default function Header() {
  return (
    <>
      <div className="header">
        <h1>Album Db</h1>   
      </div>    
    </>
  )
};

